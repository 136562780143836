* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-family: "Afacad";
}

#root {
  background-color: rgb(244, 244, 244);
}

body, #root, #app {
  -webkit-tap-highlight-color: transparent;
}

body, html {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Afacad";
  src: url("./assets/fonts/Afacad-Medium.ttf");
}
